<template>
    <div class="mt-4 wrapper flex gap-10 flex-row relative">
        <div class="ranked-icon">
            <img :src="icon" alt="Ranked icon" width="150" />
        </div>
        <div class="ranked-overlay" :style="{'background-color': progressColour}">
            <div class="flex flex-col w-full" v-if="currentSr >= 0 && currentSr < 10000">
                <div class="progress-bar-container">
                    <div class="progress-bar">
                        <div class="progress" :style="{'width': progressWidth + '%', 'background-color': this.progressBarColour}"></div>
                        <div class="divider"></div>
                        <div class="divider"></div>
                    </div>
                </div>
                <div class="flex mt-1 justify-between bg-white px-2 font-bold rounded">
                    <div class="flex flex-row gap-2 items-center">
                        <p class="bg-white p-1 rounded uppercase ">SR</p>
                        <p>{{ currentSr }}</p>
                    </div>
                    <div class="flex flex-row gap-2 items-center">
                        <p class="calculated-sr" :class="{'text-green': calculatedSR > 0, 'text-red': calculatedSR < 0}">
                            <span class="align-text-bottom" v-if="calculatedSR > 0">+</span>{{ calculatedSR }}
                        </p>
                    </div>
                    <div class="flex flex-row gap-2 items-center">
                        <p class="uppercase">{{ currentRank.replace(/[0-9]/g, '') }}</p>
                    </div>
                </div>
            </div>
            <div class="flex flex-row justify-between gap-2 items-center bg-white p-2 rounded w-full font-bold" v-if="currentSr >= 10000">
                <div class="flex flex-row">
                    <p class="uppercase mr-2">SR</p>
                    {{ currentSr }}
                </div>
                <p class="calculated-sr" :class="{'text-green': calculatedSR > 0, 'text-red': calculatedSR < 0}">
                    <span class="align-text-bottom" v-if="calculatedSR > 0">+</span>{{ calculatedSR }}
                </p>
                <div class="uppercase">
                    {{ currentRank.replace(/[0-9]/g, '') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import apiClient from '@/http-common'
import {authStore} from '@/stores/AuthStore';
import apiClient from "@/http-common";
import {getPusherChannel} from "@/services/PusherService";

export default {
    name: 'RankedOverlay',
    computed: {
        calculatedSR() {
            return this.currentSr - this.startingSr;
        }
    },
    data() {
        return {
            currentRank: 'Bronze',
            icon: '/assets/rhino/bronze1.png',
            progressColour: '#896F58',
            progressWidth: 0,
            currentSr: 0,
            startingSr: 0,
            userId: null,
            iconPack: null,
            progressBarColour: null,
        }
    },
    watch: {
        currentSr() {
            return this.processCurrentRank();
        }
    },
    mounted() {
        const auth = authStore();

        if (!auth.userId) {
            this.userId = this.$route.params.userId;
        } else {
            this.userId = auth.userId;
        }

        // Prioritise seeing the overlay you have requested in the browser. Even if logged in.
        // First condition is for when component is nested in the dashboard.
        if ((this.$route.params.userId) && auth.userId !== this.$route.params.userId) {
            this.userId = this.$route.params.userId;
        }

        const vue = this;
        const channel = getPusherChannel();
        channel.bind(`sr-update-${this.userId}`, function(data) {
            if (data.currentSr) {
                vue.currentSr = data.currentSr;
            }
            if (data.startingSr) {
                vue.startingSr = data.startingSr;
            }
        });

        this.getComputedResults();
    },
    methods: {
        getComputedResults() {
            apiClient.get(`${this.userId}/fetch-data`)
            .then((res) => {
                if (res.status !== 200) {
                    alert(res.statusText);
                    return false;
                }

                this.currentSr = res.data.currentSr;
                this.startingSr = res.data.startingSr;
                this.iconPack = res.data.iconPack;
                this.progressBarColour = res.data.progressBarColour;
                this.processCurrentRank();
            })
            .catch(() => {
                // Do nothing.
            })
        },
        processCurrentRank() {
            const rankThresholds = [
                { range: [0, 299], rank: 'bronze1' },
                { range: [300, 599], rank: 'bronze2' },
                { range: [600, 899], rank: 'bronze3' },
                { range: [900, 1299], rank: 'silver1' },
                { range: [1300, 1699], rank: 'silver2' },
                { range: [1700, 2099], rank: 'silver3' },
                { range: [2100, 2599], rank: 'gold1' },
                { range: [2600, 3099], rank: 'gold2' },
                { range: [3100, 3599], rank: 'gold3' },
                { range: [3600, 4199], rank: 'platinum1' },
                { range: [4200, 4799], rank: 'platinum2' },
                { range: [4800, 5399], rank: 'platinum3' },
                { range: [5400, 6099], rank: 'diamond1' },
                { range: [6100, 6799], rank: 'diamond2' },
                { range: [6800, 7499], rank: 'diamond3' },
                { range: [7500, 8299], rank: 'crimson1' },
                { range: [8300, 9099], rank: 'crimson2' },
                { range: [9100, 9999], rank: 'crimson3' },
                { range: [10000, Infinity], rank: 'iridescent1' }
            ];

            // Find the matching range for the current SR
            const currentRank = rankThresholds.find(({ range }) =>
                this.currentSr >= range[0] && this.currentSr <= range[1]
            );

            if (currentRank) {
                return this.setProgress(currentRank.rank);
            }
        },
        getProgressBackgroundColour(colour) {
            const colourMap = {
                silver: '#756E6B',
                gold: '#F0E490',
                platinum: '#28B4A2',
                diamond: '#0B578B',
                crimson: '#89070B',
                iridescent: '#AC4FCB'
            };

            const colourKey = colour.slice(0, -1); // Remove last character from colour
            return colourMap[colourKey] || '#896F58'; // Default to Bronze
        },
        getIconPath(icon) {
            const pack = this.iconPack;
            const iconPaths = {
                bronze1: `/assets/${pack}/bronze1.png`,
                bronze2: `/assets/${pack}/bronze2.png`,
                bronze3: `/assets/${pack}/bronze3.png`,
                silver1: `/assets/${pack}/silver1.png`,
                silver2: `/assets/${pack}/silver2.png`,
                silver3: `/assets/${pack}/silver3.png`,
                gold1: `/assets/${pack}/gold1.png`,
                gold2: `/assets/${pack}/gold2.png`,
                gold3: `/assets/${pack}/gold3.png`,
                platinum1: `/assets/${pack}/platinum1.png`,
                platinum2: `/assets/${pack}/platinum2.png`,
                platinum3: `/assets/${pack}/platinum3.png`,
                diamond1: `/assets/${pack}/diamond1.png`,
                diamond2: `/assets/${pack}/diamond2.png`,
                diamond3: `/assets/${pack}/diamond3.png`,
                crimson1: `/assets/${pack}/crimson1.png`,
                crimson2: `/assets/${pack}/crimson2.png`,
                crimson3: `/assets/${pack}/crimson3.png`,
                iridescent1: `/assets/${pack}/iridescent.png`
            };

            // Return the corresponding path or default to '/assets/rhino/bronze1.png'
            return iconPaths[icon] || `/assets/${pack}/bronze1.png`;
        },
        getProgressWidth(rank) {
            const rankRanges = {
                silver: [900, 2099],
                gold: [2100, 3599],
                platinum: [3600, 5399],
                diamond: [5400, 7499],
                crimson: [7500, 9999],
                iridescent: [10000, 30000]
            };

            const rankKey = rank.slice(0, -1); // Remove last character from rank
            const [min, max] = rankRanges[rankKey] || [0, 899]; // Default to Bronze range
            return this.calculateProgress(min, max);
        },
        calculateProgress(lowerLimit, upperLimit) {
            return ((this.currentSr - lowerLimit) / (upperLimit - lowerLimit)) * 100;
        },
        setProgress(rank) {
            this.currentRank = rank;
            this.icon = this.getIconPath(rank);
            this.progressColour = this.getProgressBackgroundColour(rank);
            this.progressWidth = this.getProgressWidth(rank);
        },
    }
}
</script>