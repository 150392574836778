<template>
    <div class="login-form bg-gray-200">
        <div class="flex items-center justify-center min-h-screen">
            <div class="lg:w-full w-3/4 max-w-md p-6 bg-white rounded-lg shadow-md">
                <!-- Login Form -->
                <form id="loginForm" class="space-y-6">
                    <h2 class="text-2xl font-bold text-center">Login</h2>

                    <div class="bg-green-100 p-4 rounded-lg" v-if="!!registerSuccess">
                        <p class="font-bold">Success!</p>
                        <p>
                            You can now successfully login.
                        </p>
                    </div>

                    <div class="bg-red-200 p-4 rounded-lg" v-if="!!errorMessage">
                        <p class="font-bold">Error!</p>
                        <p>
                            Something went wrong, please get in touch if issue persists.
                        </p>
                    </div>

                    <div>
                        <label for="login-email" class="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            v-model="email"
                            type="email"
                            id="login-email"
                            required
                            class="block w-full mt-1 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            @blur="v$.email.$touch"
                            autocomplete="email"
                        >
                        <p class="text-red text-xs" v-if="v$.email.$errors[0]">{{ v$.email.$errors[0].$message }}</p>
                    </div>

                    <div>
                        <label for="login-password" class="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            v-model="password"
                            type="password"
                            id="login-password"
                            required
                            class="block w-full mt-1 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            @blur="v$.password.$touch"
                            autocomplete="password"
                        >
                        <p class="text-red text-xs" v-if="v$.password.$errors[0]">{{ v$.password.$errors[0].$message }}</p>
                    </div>

                    <button
                        type="submit"
                        class="w-full py-2 mt-4 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                        @click.prevent="login()"
                        :disabled="loading === true"
                    >
                        Login
                        <i class="fa-solid fa-spinner fa-spin" v-if="loading === true"></i>
                    </button>

                    <p class="text-center text-sm text-gray-600">Not signed up yet?
                        <router-link to="register" class="text-blue-500 hover:underline">Register here</router-link>.
                    </p>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import apiClient from '@/http-common';
import {authStore} from '@/stores/AuthStore';
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'

export default {
    name: 'LoginView',
    setup() {
        return { v$: useVuelidate() }
    },
    props: {
        registerSuccess: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            email: null,
            password: null,
            loading: false,
            errorMessage: false,
        }
    },
    validations: {
        email: {
            required: helpers.withMessage('Email is a required field.', required),
            email: helpers.withMessage('Invalid email address', email)
        },
        password: {
            required: helpers.withMessage('Password is a required field.', required),
        }
    },
    methods: {
        login() {
            this.loading = true;
            this.v$.$reset();
            this.v$.email.$touch();
            this.v$.password.$touch();

            if (this.v$.$errors.length > 0) {
                return false;
            }

            apiClient.post('/login', {
                email: this.email,
                password: this.password,
            })
                .then((res) => {
                    if (res.status !== 200) {
                        this.errorMessage = true;
                        return;
                    }
                    const auth = authStore();
                    auth.setToken(res.data.token);
                    auth.setUserId(res.data.userId);
                    this.$router.push({name: 'dashboard'});
                })
                .catch(() => {
                    this.errorMessage = true;
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    }
}
</script>